<template>
  <div>
    <ayl-berad-nav :nav="nav"></ayl-berad-nav>
    <div class="content-box">
      <div class="content-main mb24px" style="padding-bottom: 0;">
        <ayl-search-box :query="table.query" :search-data='searchData' @on-search-change='onSearchChange'>
          <div slot="after">
            <el-button type="primary" @click="goList">对账单列表</el-button>
          </div>
        </ayl-search-box>
      </div>
      <div class="content-main mb24px">
        <div class="accounting-data" v-for="(item,idx) in accountingData" :key="idx">
          <span>{{statisticsMap[item.value]}}</span>
          <span>{{item.label}}</span>
        </div>
      </div>
      <div class="content-main">
        <ayl-table :table="table" style="width: 100%"></ayl-table>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data () {
      const vm = this
      return {
        nav: [
          {
            name: '会计对账',
            // path: '/accounting/customer-reconciliation'
          },
          {name: '客户对账'},
        ],
        searchData: [
          {
          type: 'dateSection',
          title: '查询日期',
          // 是否需要快捷选项
          isOptions: true,
          model: [],
          modelName: ['startTime', 'endTime'],
         }, 
        {
          type: 'select',
          title: '业务类型',
          model: 'orderType',
          placeholder: '请选择',
          option: vm.$enums.OrderType.list
        }, {
          type: 'input',
          model: 'queryContent',
          placeholder: '结算客户名称'
        }],
        table: {
          other: {},
          api: vm.$api.CheckingInfoStatistics,
          query: {
            startTime: '',
            endTime: '',
            orderType: null,
            queryContent: null,
          },
          // 表格查询的条件组件
          columns: [{
            title: '结算客户',
            key: 'partnerAccountName',
            width: '150px',
            fixed: "left",
            showTooltip: true,
          }, {
            title: '待对账订单数',
            key: 'orderCount',
            width: '150px',
            fixed: "left",
          }, {
            title: '应收金额',
            key: 'reciveable',
            width: '150px',
            fixed: "left",
          }, {
            title: '收入',
            key: 'income',
            width: '150px',
            fixed: "left",
          }, {
            title: '代垫费用',
            key: 'advanceFee',
          }, {
            title: '吊装费',
            width: '120px',
            key: 'liftingFee',
          }, {
            title: '过磅费',
            key: 'weightFee',
          }, {
            title: '卸车费',
            key: 'unloadFee',
          }, {
            title: '拼车费',
            key: 'carpoolingFee',
          }, {
            title: '操作',
            align: 'center',
            width: '180px',
            render (h, ctx) {
              return h('span', [
                h('span', {
                  class: {
                    'table-view': true,
                  },
                  on: {
                    click: vm.goAccounting.bind(this, ctx.row)
                  }
                }, '订单明细'),
                h('span', {
                  class: {
                    'table-view': true,
                  },
                  on: {
                    click: vm.goAccountingList.bind(this, ctx.row)
                  }
                }, '对账单列表'),
              ],
              )
            }
          }]
        },
        accountingData: [
          {
            value: 'totalOrderCount',
            label: '待对账订单数'
          }, {
            value: 'totalReciveable',
            label: '应收金额(元)'
          }, {
            value: 'totalIncome',
            label: '收入(元)'
          }, {
            value: 'totalAdvanceFee',
            label: '代垫费用(元)'
          }
        ],
        statisticsMap: {}
      }
    },
    // /examine/bus-accounting
    methods: {
      goList(){
         this.$router.push({path:"/accounting/customer-reconciliation-list"})
      },


      goAccountingList(e){
        this.$router.push({
          path: '/accounting/statement-account-list',
          query: {
            partnerAccountId: e.partnerAccountId,
            partnerAccountName: e.partnerAccountName,
            startTime: this.table.query.startTime,
            endTime: this.table.query.endTime,
            orderType: this.table.query.orderType,
          }
        })
      },

      goAccounting (e) {
        console.log( e.partnerAccountId)
        this.$router.push({
          path: '/accounting/order-detailed',
          query: {
            partnerAccountId: e.partnerAccountId,
            partnerAccountName: e.partnerAccountName,
            startTime: this.table.query.startTime,
            endTime: this.table.query.endTime,
            orderType: this.table.query.orderType,
          }
        })
      },
      
      onSearchChange (e) {
        this.$search(this.table, e)
        if (!this.table.hidePagination) {
          this.$set(this.table.pagination, 'currentPage', 1)
        }
      }
    },
    watch: {
      'table.other': {
        handler (newName) {
          this.statisticsMap = newName
        },
        deep: true
      },
    },
    beforeRouteEnter (to, from, next) {
      if (from.path === '/accounting/order-detailed') {
        to.meta.isBack = true
      } else {
        to.meta.isBack = false
      }
      next()
    },
    async activated () {
      if (!this.$route.meta.isBack) {
        this.table.query.orderType = '',
        this.$set(this.table.query,'startTime',null),
        this.$set(this.table.query,'endTime',null),
        this.searchData[0].model = [],
        this.searchData[0].modelName = ['startTime','endTime'],
        this.table.query.queryContent = null,
        // await this.$search(this.table)
        this.statisticsMap = this.table.other
      } else {
          // await this.$search(this.table)
          this.$route.meta.isBack = false // 重置详情页标识isBack
      }
    },
    async mounted () {
    },
    // async activated () {
    //   await this.$search(this.table)
    //   this.statisticsMap = this.table.other
    // }
  }
</script>

<style lang='sass' scoped>
  .content-main
    display: flex
  /deep/ .date-section
    display: none
</style>
